import React from 'react';
import { useState } from "react"
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css'; // This only needs to be imported once in your app
import ResponsiveImage from '../components/responsiveImage';

const LightboxExample = ({ pageContext }) => {

    const images = pageContext.images;
    const [photoIndex, setIndex] = useState(0);
    const [isOpen, setIsOpen] = useState(false);

    const array = []

    images.forEach(image =>
      array.push(<ResponsiveImage image={image} />)
    )

    return (
      <div>
        <button type="button" onClick={() => setIsOpen(true)}>
          Open Lightbox
        </button>

        {isOpen && (
          <Lightbox
            mainSrc={array[photoIndex]}
            nextSrc={array[(photoIndex + 1) % array.length]}
            prevSrc={array[(photoIndex + array.length - 1) % array.length]}
            onCloseRequest={() => setIsOpen(false)}
            onMovePrevRequest={() =>
              setIndex((photoIndex + array.length - 1) % array.length)
            }
            onMoveNextRequest={() =>
              setIndex((photoIndex + 1) % array.length)
            }
            imageTitle="Title of the Image"
            imageCaption="This is a caption for the image"
          />
        )}
      </div>
    );   
  } 

export default LightboxExample